import React from 'react'

import { selectProjectById } from '../api/for-company/projects.api'
import { ForProjectRole } from '../components/Auth/ForProjectRole'
import { ToolbarActions } from '../components/Drawers/Toolbar'
import { AircraftInformationDrawer } from '../components/Projects/AircraftInformation/AircraftInformationDrawer'
import { ROILSummary } from '../components/ROIL/ROILSummary'
import { ProjectRolePermissions } from '../domain/project-role.domain'
import { routes } from '../routes'
import { useAppSelector } from '../store'

import { Page } from './shared/Page'

export const ROILSummaryPage: React.FC = () => {
    const { projectId } = routes.ROILSummary.useParams()
    const project = useAppSelector(selectProjectById(projectId))

    if (!project) {
        return null
    }

    return (
        <ForProjectRole
            permission={ProjectRolePermissions.ProjectViewSummary}
            projectId={projectId}
        >
            <Page logo={project.auditorLogo}>
                <ROILSummary projectId={projectId} />
            </Page>
            <ToolbarActions>
                <ForProjectRole
                    permission={ProjectRolePermissions.AircraftInformationView}
                    projectId={projectId}
                >
                    <AircraftInformationDrawer projectId={projectId} />
                </ForProjectRole>
            </ToolbarActions>
        </ForProjectRole>
    )
}
