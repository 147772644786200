import React from 'react'

import { useGetPOILSummaryQuery } from '../api/for-company/oil.api'
import { selectProjectById } from '../api/for-company/projects.api'
import { ForProjectRole } from '../components/Auth/ForProjectRole'
import { ToolbarActions } from '../components/Drawers/Toolbar'
import { ListPOIL } from '../components/OIL/POIL'
import { StatusesOverview } from '../components/OIL/StatusesOverview'
import { AircraftInformationDrawer } from '../components/Projects/AircraftInformation/AircraftInformationDrawer'
import { ProjectRolePermissions } from '../domain/project-role.domain'
import { routes } from '../routes'
import { useAppSelector } from '../store'

import { Page } from './shared/Page'

const POILToolbarActions: React.FC<{ projectId: number; subjectId: number }> = props => {
    const { projectId, subjectId } = props
    const { data, isLoading } = useGetPOILSummaryQuery({ id: projectId })
    const oilSummary = data?.results.find(s => s.id === subjectId)

    return (
        <ToolbarActions>
            <ForProjectRole permission={ProjectRolePermissions.OILList} projectId={projectId}>
                <StatusesOverview oilSummary={oilSummary} isLoading={isLoading} />
            </ForProjectRole>
            <ForProjectRole
                permission={ProjectRolePermissions.AircraftInformationView}
                projectId={projectId}
            >
                <AircraftInformationDrawer projectId={projectId} />
            </ForProjectRole>
        </ToolbarActions>
    )
}

export const POILPage: React.FC = () => {
    const { projectId, subjectId } = routes.POIL.useParams()
    const project = useAppSelector(selectProjectById(projectId))

    if (!project) {
        return null
    }

    return (
        <ForProjectRole permission={ProjectRolePermissions.OILList} projectId={projectId}>
            <Page logo={project.auditorLogo}>
                <ListPOIL projectId={projectId} subjectId={subjectId} />
            </Page>
            <POILToolbarActions projectId={projectId} subjectId={subjectId} />
        </ForProjectRole>
    )
}
