import React from 'react'

import { selectProjectById } from '../api/for-company/projects.api'
import { ForProjectRole } from '../components/Auth/ForProjectRole'
import { AircraftInformation } from '../components/Projects/AircraftInformation/AircraftInformation'
import { ProjectRolePermissions } from '../domain/project-role.domain'
import { routes } from '../routes'
import { useAppSelector } from '../store'

import { Page } from './shared/Page'

export const AircraftInformationPage: React.FC = () => {
    const { projectId } = routes.ProjectAircraftInformation.useParams()
    const project = useAppSelector(selectProjectById(projectId))

    if (!project) {
        return null
    }

    return (
        <ForProjectRole
            permission={ProjectRolePermissions.AircraftInformationView}
            projectId={projectId}
        >
            <Page logo={project.auditorLogo}>
                <AircraftInformation projectId={projectId} />
            </Page>
        </ForProjectRole>
    )
}
