import { mdiAirplane, mdiLoading } from '@mdi/js'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAircraftInformationQuery } from '../../../api/for-company/aircraft-information.api'
import { selectProjectById } from '../../../api/for-company/projects.api'
import { IconButton } from '../../../atoms/IconButton'
import { Tooltip } from '../../../atoms/Tooltip'
import { isSuccess } from '../../../common/asyncState'
import { range, isNil } from '../../../common/utils'
import {
    AircraftInformation,
    AircraftInformationBlockType,
    AircraftInformationModule,
} from '../../../domain/aircraft-information.domain'
import { Project } from '../../../domain/project.domain'
import { useAppSelector } from '../../../store'
import { InfoDrawer } from '../../Drawers/InfoDrawer'

import { moduleMap } from './aircraftInformationModuleMap'

const AircraftInformationDrawerModule: React.FC<{
    module: AircraftInformationModule
    columns: number
    mainColor: string
    mainColorFaded: string
    gridSeparatorColor: string
    gridTone: string
}> = props => {
    const { t } = useTranslation('aircraftInformation')
    const { blocks } = props.module
    const infoArrayLength = blocks.filter(e => e.name !== null).length
    const lines = Math.round(infoArrayLength / props.columns)

    return (
        <div className='w-full bg-white bg-opacity-90 border-b-4 border-ebony-400 shadow-xl'>
            <div
                className={classNames(
                    'py-3 font-semibold text-lg leading-6 text-center align-middle',
                    props.mainColor,
                )}
            >
                {props.module.name}
            </div>
            <div>
                {range(lines).map(i => (
                    <div className={classNames('flex py-3', props.gridTone)} key={i}>
                        {range(props.columns).map(j => {
                            const blockIndex = i * props.columns + j
                            if (!blocks[blockIndex]) {
                                return null
                            }
                            return blocks[blockIndex].name ? (
                                <div
                                    className={classNames('w-1/2 flex', {
                                        [props.gridSeparatorColor]: (j + 1) % 2,
                                    })}
                                    key={j}
                                >
                                    {blockIndex < blocks.length ? (
                                        <div
                                            className={classNames(
                                                'flex flex-col w-full px-3 text-atamblue-900',
                                            )}
                                        >
                                            <div className='text-xs leading-6'>
                                                {blocks[blockIndex].name}
                                            </div>
                                            <div className='font-medium text-center '>
                                                {blocks[blockIndex].value ? (
                                                    blocks[blockIndex].type ===
                                                    AircraftInformationBlockType.Date ? (
                                                        blocks[blockIndex].value ? (
                                                            format(
                                                                new Date(
                                                                    (
                                                                        blocks[blockIndex] as any
                                                                    ).value,
                                                                ),
                                                                'dd-MMM-yyyy',
                                                            )
                                                        ) : null
                                                    ) : blocks[blockIndex].type ===
                                                      AircraftInformationBlockType.Number ? (
                                                        `${blocks[blockIndex].value} ${blocks[blockIndex].params.unit}`
                                                    ) : (
                                                        blocks[blockIndex].value
                                                    )
                                                ) : (
                                                    <span className={classNames(props.mainColor)}>
                                                        {t('ToBeDefined')}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}

export const AircraftInformationDrawerContent: React.FC<{ info: AircraftInformation }> = props => (
    <div className='flex flex-wrap flex-column w-1/3 h-full'>
        {props.info.modules.map(module => (
            <AircraftInformationDrawerModule
                key={module.name}
                module={module}
                columns={2}
                mainColor={moduleMap[module.type].mainColor}
                mainColorFaded={moduleMap[module.type].mainColorFaded}
                gridSeparatorColor={moduleMap[module.type].gridSeparatorColor}
                gridTone={moduleMap[module.type].gridTone}
            />
        ))}
    </div>
)

export const AircraftInformationDrawer: React.FC<{ projectId: Project['id'] }> = props => {
    const { t } = useTranslation('toolbar')

    const project = useAppSelector(selectProjectById(props.projectId))
    const { data, isLoading } = useGetAircraftInformationQuery({ projectId: props.projectId })

    if (isNil(project)) {
        return null
    }

    return (
        <InfoDrawer
            render={({ open }) => (
                <Tooltip text={t('AircraftInformation')} className='inline-flex'>
                    <IconButton
                        path={isLoading ? mdiLoading : mdiAirplane}
                        spin={isLoading}
                        isSubmit
                        className='w-16 text-atamblue-300 hover:text-atamsky-900'
                        iconClassName='mr-0 '
                        onClick={() => {
                            open()
                        }}
                    ></IconButton>
                </Tooltip>
            )}
            body={() => {
                if (!isSuccess || isNil(data)) {
                    return null
                }
                return <AircraftInformationDrawerContent info={data} />
            }}
        ></InfoDrawer>
    )
}
