import { mdiCalendarCheck, mdiAlertDecagram } from '@mdi/js'
import Icon from '@mdi/react'
import { differenceInMilliseconds, format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGetDashboardQuery } from '../api/for-company/dashboard.api'
import { selectProfile } from '../api/for-company/profile.api'
import { selectAllProjects } from '../api/for-company/projects.api'
import { SelectInput } from '../atoms/Form/SelectInput'
import { PageLoadingSpinner } from '../atoms/PageLoadingSpinner'
import { ProgressRing } from '../atoms/ProgressRing'
import { Separator } from '../atoms/Separator'
import { Tooltip } from '../atoms/Tooltip'
import { ForAccountType } from '../components/Auth/ForAccountType'
import { AccountTypePermissions } from '../domain/account-type.domain'
import { DashboardOrderBy } from '../domain/Dashboard'
import { User } from '../domain/user.domain'
import { useTranslation } from '../lib/i18n'
import { routes } from '../routes'
import { useAppSelector } from '../store'

import { Page } from './shared/Page'

const getFirstName = (user?: Pick<User, 'name'>): string => (user ? user.name.split(' ')[0] : '')
const round = (nr: number) => (isNaN(nr) ? 0 : Math.round(nr))
const divide = (a: number, b: number): number => (b === 0 ? 0 : a / b)
const getPercent = (value: number): number => Math.max(Math.min(value * 100, 100), 0)

const NotApplicable: React.FC = () => {
    return <div className='text-center'>N/A</div>
}

const OrderBySelector: React.FC<{
    value: DashboardOrderBy
    onChange: (value: DashboardOrderBy) => void
}> = props => {
    const criteria = useMemo(
        () => [
            { value: DashboardOrderBy.ProjectNameAsc, label: 'Project Name (A to Z)' },
            { value: DashboardOrderBy.ProjectNameDesc, label: 'Project Name (Z to A)' },
            { value: DashboardOrderBy.TimeProgressAsc, label: 'Time Progress(Ascending)' },
            { value: DashboardOrderBy.TimeProgressDesc, label: 'Time Progress(Descending)' },
            { value: DashboardOrderBy.ProjectProgressAsc, label: 'Project Progress(Ascending)' },
            { value: DashboardOrderBy.ProjectProgressDesc, label: 'Project Progress(Descending)' },
        ],
        [],
    )
    const { t } = useTranslation('dashboard')

    return (
        <div className='flex items-center mb-1 text-sm'>
            <div className='mr-1 text-atamblue-900'>{t('OrderBy')}</div>
            <SelectInput
                options={criteria}
                value={props.value}
                placeholder={'Order By'}
                className='w-64'
                onChange={value => {
                    if (value) {
                        props.onChange(value)
                    }
                }}
            />
        </div>
    )
}

const DashboardStatistics = () => {
    const { t } = useTranslation('dashboard')
    const [orderBy, setOrderBy] = useState(DashboardOrderBy.ProjectNameAsc)
    const getDashboardMeta = useGetDashboardQuery()
    const allProjects = useAppSelector(selectAllProjects())

    if (!getDashboardMeta.data) {
        return null
    }

    const { results } = getDashboardMeta.data

    return (
        <>
            <div className='flex justify-end'>
                <OrderBySelector value={orderBy} onChange={setOrderBy} />
            </div>
            {getDashboardMeta.isLoading ? (
                <PageLoadingSpinner />
            ) : results.length === 0 ? (
                <div className='flex flex-col h-60 place-items-center place-content-center'>
                    <div className='font-black text-3xl text-atamsky-900'>{t('Welcome')}</div>
                    <div className='font-medium text-lg text-atamblue-900'>
                        {t('EmptyBoardMsg')}
                        <Link
                            className='underline text-atamblue-700 hover:text-atamsky-900'
                            to={routes.CreateProject.build()}
                        >
                            {t('AddNewProjectLinkText')}
                        </Link>
                    </div>
                </div>
            ) : (
                results.map(statistics => {
                    const project = allProjects.find(p => p.id === statistics.id)

                    const timeProgress = getPercent(
                        divide(
                            Math.max(
                                differenceInMilliseconds(
                                    Date.now(),
                                    new Date(statistics.startDate),
                                ),
                                0,
                            ),
                            differenceInMilliseconds(
                                new Date(statistics.endDate),
                                new Date(statistics.startDate),
                            ),
                        ),
                    )

                    const rasProgress = getPercent(
                        divide(statistics.ras.closed, statistics.ras.total),
                    )
                    const pasProgress = getPercent(
                        divide(statistics.pas.closed, statistics.pas.total),
                    )

                    const roilProgress = getPercent(
                        divide(statistics.roil.closed, statistics.roil.total),
                    )
                    const poilProgress = getPercent(
                        divide(statistics.poil.closed, statistics.poil.total),
                    )

                    const totalRoilCriticalItems = statistics.roil.criticalPath.reduce(
                        (acc, item) => acc + item.count,
                        0,
                    )
                    const totalPoilCriticalItems = statistics.poil.criticalPath.reduce(
                        (acc, item) => acc + item.count,
                        0,
                    )

                    return (
                        <div
                            key={statistics.id}
                            className='bg-ebony-100 mb-2 rounded-2xl shadow-md overflow-hidden text-atamblue-700'
                        >
                            <div className='flex bg-white border-b-4 border-ebony-400'>
                                <div className='w-1/3 bg-atamsky-200 text-atamblue-700 skew-x-12'>
                                    <Tooltip text={statistics.name}>
                                        <div className='w-11/12 py-4 pl-5 bg-atamsky-200 -skew-x-12 truncate'>
                                            {t('ProjectName')}:{' '}
                                            <span className='font-medium text-atamblue-900'>
                                                {statistics.name}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='w-1/3 px-8 py-4 text-center'>
                                    {t('AuditorName')}:{' '}
                                    <span className='pl-2 font-medium text-green-500 '>
                                        {statistics.auditorName}
                                    </span>
                                </div>
                                <div className='w-1/3 px-8 py-4 text-center'>
                                    {t('AuditeeName')}:{' '}
                                    <span className='pl-2 font-medium text-teal-700'>
                                        {statistics.auditeeName}
                                    </span>
                                </div>
                            </div>
                            <div className='flex py-3 text-sm'>
                                <div className='w-1/4 flex'>
                                    <div className='w-full mt-6 px-3'>
                                        <div className='text-center font-medium text-base my-2'>
                                            {t('TimeProgress')}:
                                        </div>
                                        <ProgressRing
                                            progress={round(timeProgress)}
                                            size={84}
                                            circleFill={'transparent'}
                                            strokeWidth={12}
                                            circularBackground={'#E5E5E5'}
                                            circularProgress={'#F0B006'}
                                            svgClassName='mx-auto'
                                            circularBackgroundClassName='shadow-inner'
                                            circularProgressClassName='shadow-inner'
                                            labelClassName='text-lg font-medium'
                                            labelColor={'#64A8C6'}
                                        />
                                        <div className='flex justify-around mt-3'>
                                            <div className='flex items-center border-b border-yellow-700 px-1 text-center'>
                                                <div>{t('Start')}:</div>
                                                <div className='ml-1'>
                                                    {format(
                                                        new Date(statistics.startDate),
                                                        'd-MMM-u',
                                                    )}
                                                </div>
                                            </div>
                                            <div className='flex items-center border-b border-green-700 px-1 text-center'>
                                                <div>{t('End')}:</div>
                                                <div className='ml-1'>
                                                    {format(
                                                        new Date(statistics.endDate),
                                                        'd-MMM-u',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Separator className='w-px h-36 bg-ebony-400' />
                                </div>
                                <div className='w-3/8'>
                                    <div className='text-center font-medium text-base'>
                                        {t('AuditStatus')}:
                                    </div>
                                    <div className='flex'>
                                        <div className='w-1/2 px-3'>
                                            <div className='text-center font-medium text-base my-2'>
                                                {t('RecordsAudit')}:
                                            </div>
                                            {project?.hasRAS ? (
                                                <>
                                                    <ProgressRing
                                                        progress={round(rasProgress)}
                                                        size={84}
                                                        circleFill={'transparent'}
                                                        strokeWidth={12}
                                                        circularBackground={'#E5E5E5'}
                                                        circularProgress={'#4CA59A'}
                                                        svgClassName='mx-auto'
                                                        circularBackgroundClassName='shadow-inner'
                                                        circularProgressClassName='shadow-inner'
                                                        labelClassName='text-lg font-medium'
                                                        labelColor={'#64A8C6'}
                                                    />
                                                    <div className='flex leading-4 text-center'>
                                                        <div className='w-1/2 flex-col mr-2'>
                                                            <div>{t('CompletedItems')}:</div>
                                                            <div className='text-green-500'>
                                                                {statistics.ras.closed}
                                                            </div>
                                                        </div>
                                                        <div className='w-1/2 flex-col ml-2'>
                                                            <div>{t('TotalItems')}:</div>
                                                            <div className=''>
                                                                {statistics.ras.total}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <NotApplicable />
                                            )}
                                        </div>
                                        <div className='w-1/2 px-3'>
                                            <div className='text-center font-medium text-base my-2'>
                                                {t('Physical')}:
                                            </div>
                                            {project?.hasPAS ? (
                                                <>
                                                    <ProgressRing
                                                        progress={round(pasProgress)}
                                                        size={84}
                                                        circleFill={'transparent'}
                                                        strokeWidth={12}
                                                        circularBackground={'#E5E5E5'}
                                                        circularProgress={'#A079BF'}
                                                        svgClassName='mx-auto'
                                                        circularBackgroundClassName='shadow-inner'
                                                        circularProgressClassName='shadow-inner'
                                                        labelClassName='text-lg font-medium'
                                                        labelColor={'#64A8C6'}
                                                    />
                                                    <div className='flex leading-4 text-center'>
                                                        <div className='w-1/2 flex-col mr-2'>
                                                            <div>{t('CompletedItems')}:</div>
                                                            <div className='text-green-500'>
                                                                {statistics.pas.closed}
                                                            </div>
                                                        </div>
                                                        <div className='w-1/2 flex-col ml-2'>
                                                            <div>{t('TotalItems')}:</div>
                                                            <div className=''>
                                                                {statistics.pas.total}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <NotApplicable />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Separator className='w-px h-36 bg-ebony-400' />
                                <div className='w-3/8 flex'>
                                    <div className='w-full'>
                                        <div className='text-center font-medium text-base'>
                                            {t('OpenItemListing')}:
                                        </div>
                                        <div className='flex'>
                                            <div className='w-1/2 px-3'>
                                                <div className='text-center font-medium text-base my-2'>
                                                    {t('RecordsAudit')}:
                                                </div>
                                                {project?.hasROIL ? (
                                                    <>
                                                        <ProgressRing
                                                            progress={round(roilProgress)}
                                                            size={84}
                                                            circleFill={'transparent'}
                                                            strokeWidth={12}
                                                            circularBackground={'#E5E5E5'}
                                                            circularProgress={'#4CA59A'}
                                                            svgClassName='mx-auto'
                                                            circularBackgroundClassName='shadow-inner'
                                                            circularProgressClassName='shadow-inner'
                                                            labelClassName='text-lg font-medium'
                                                            labelColor={'#64A8C6'}
                                                        />
                                                        <div className='flex leading-4 text-center'>
                                                            <div className='w-1/2 flex-col mr-2'>
                                                                <div>{t('CompletedItems')}:</div>
                                                                <div className='text-green-500'>
                                                                    {statistics.roil.closed}
                                                                </div>
                                                            </div>
                                                            <div className='w-1/2 flex-col ml-2'>
                                                                <div>{t('TotalItems')}:</div>
                                                                <div className=''>
                                                                    {statistics.roil.total}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <NotApplicable />
                                                )}
                                            </div>
                                            <div className='w-1/2 px-3'>
                                                <div className='text-center font-medium text-base my-2'>
                                                    {t('PhysicalAudit')}:
                                                </div>
                                                {project?.hasPOIL ? (
                                                    <>
                                                        <ProgressRing
                                                            progress={round(poilProgress)}
                                                            size={84}
                                                            circleFill={'transparent'}
                                                            strokeWidth={12}
                                                            circularBackground={'#E5E5E5'}
                                                            circularProgress={'#A079BF'}
                                                            svgClassName='mx-auto'
                                                            circularBackgroundClassName='shadow-inner'
                                                            circularProgressClassName='shadow-inner'
                                                            labelClassName='text-lg font-medium'
                                                            labelColor={'#64A8C6'}
                                                        />
                                                        <div className='flex leading-4 text-center'>
                                                            <div className='w-1/2 flex-col mr-2'>
                                                                <div>{t('CompletedItems')}:</div>
                                                                <div className='text-green-500'>
                                                                    {statistics.poil.closed}
                                                                </div>
                                                            </div>
                                                            <div className='w-1/2 flex-col ml-2'>
                                                                <div>{t('TotalItems')}:</div>
                                                                <div className=''>
                                                                    {statistics.poil.total}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <NotApplicable />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='m-4'>
                                <div className='flex border-t border-ebony-400'>
                                    <div className='w-1/3 py-3 self-center font-medium text-base text-atamblue-700'>
                                        {t('RoilCriticalPathItems')}: {totalRoilCriticalItems}
                                    </div>
                                    <div className='grid grid-cols-3 w-full py-2'>
                                        {statistics.roil.criticalPath
                                            .filter(item => item.count > 0)
                                            .map(item => (
                                                <div key={item.name} className='group'>
                                                    <Link
                                                        to={routes.ROIL.build({
                                                            projectId: statistics.id,
                                                            subjectId: item.id,
                                                            showCriticalPathItemsOnly: true,
                                                        } as any)}
                                                        className='flex items-center m-2 text-sm group-hover:text-atamsky-900'
                                                    >
                                                        <Icon
                                                            path={mdiAlertDecagram}
                                                            size={0.75}
                                                            className='mr-2 text-atamblue-300 group-hover:text-atamsky-900'
                                                        />
                                                        {item.name} ({item.count})
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                <div className='flex border-t border-ebony-400'>
                                    <div className='w-1/3 py-3 self-center font-medium text-base text-atamblue-700'>
                                        {t('PoilCriticalPathItems')}: {totalPoilCriticalItems}
                                    </div>
                                    <div className='grid grid-cols-3 w-full pt-2'>
                                        {statistics.poil.criticalPath
                                            .filter(item => item.count > 0)
                                            .map(item => (
                                                <div key={item.name} className='group'>
                                                    <Link
                                                        to={routes.POIL.build({
                                                            projectId: statistics.id,
                                                            subjectId: item.id,
                                                            showCriticalPathItemsOnly: true,
                                                        } as any)}
                                                        key={item.name}
                                                        className='flex items-center m-2 text-sm group-hover:text-atamsky-900'
                                                    >
                                                        <Icon
                                                            path={mdiAlertDecagram}
                                                            size={0.75}
                                                            className='mr-2 text-atamblue-300 group-hover:text-atamsky-900'
                                                        />
                                                        {item.name} ({item.count})
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            )}
        </>
    )
}

export const DashboardPage: React.FC = () => {
    const profile = useAppSelector(selectProfile)
    return (
        <ForAccountType permission={AccountTypePermissions.DashboardView}>
            <Page
                breadcrumbs={
                    <div className='flex items-center text-sm'>
                        <div className='font-medium text-atamsky-900'>
                            Welcome, {getFirstName(profile)}!
                        </div>
                        <Separator className='w-0.5 h-8 mx-2 bg-atamblue-300' />
                        <div key='current-date' className='flex items-center text-atamblue-700'>
                            <Icon path={mdiCalendarCheck} size={0.9} className='mr-1' />
                            {format(new Date(), 'EEEE, d LLL u')}
                        </div>
                    </div>
                }
            >
                <DashboardStatistics />
            </Page>
        </ForAccountType>
    )
}
