import classNames from 'classnames'
import React from 'react'
import { Styles } from 'react-select'
import Async from 'react-select/async'

import { debounce } from '../common/debounce'

// eslint-disable-next-line @typescript-eslint/ban-types
export const styles: Partial<Styles<{}, false>> = {
    control: provided => ({
        ...provided,
        backgroundColor: 'transparent',
        border: 'none',
    }),

    container: (provided, state) => ({
        ...provided,
        borderBottom: state.isDisabled ? 'none' : 'solid 1px #96c4d7',
        lineHeight: '36px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display,
        width: '3rem',
        color: state.isFocused ? '#00a7e1' : '#96c4d7',
        '&:hover': { color: '#00a7e1' },
        textAlign: 'center',
    }),

    input: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    placeholder: provided => ({
        ...provided,
        color: '#64A8C6',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#96c4d7' : '#0072a3',
        backgroundColor: state.isFocused ? '#a6e1f3' : '#fff',
        padding: '0.75rem 0.5rem',
        lineHeight: '1.25rem',
    }),

    singleValue: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    loadingIndicator: provided => ({
        ...provided,
        color: '#a6e1f3',
    }),
}

export const EntitySelector = <T extends { id: number }>(
    props: React.PropsWithChildren<{
        resolver: (query: string) => Promise<T[]>
        icon?: JSX.Element
        value: T | undefined
        exclude?: T[]
        onChange: (entity: T) => void
        placeholder?: string
        isOptionSelected?: (option: T, selected: ReadonlyArray<T>) => boolean
        getOptionLabel?: (entity: T) => string
        className?: string
        disabled?: boolean
    }>,
): React.ReactElement<any, any> | null => {
    const debouncedResolver = React.useCallback(
        debounce(
            (inputValue: any, callback: any) => {
                props.resolver(inputValue).then(options => callback(options))
            },
            500,
            {},
        ),
        [],
    )
    const getOptionLabel = props.getOptionLabel ?? ((entity: any) => entity.name ?? entity.label)
    const isOptionSelected =
        props.isOptionSelected ??
        ((option: T, selected: ReadonlyArray<T>) => {
            return selected.map(s => s.id).includes(option.id)
        })
    const excluded = props.exclude ?? []
    const isOptionDisabled = (option: T) => excluded.map(e => e.id).includes(option.id)

    return (
        <div className={classNames('flex grow items-center', props.className)}>
            {props.icon}
            <Async
                classNamePrefix='react-select'
                className='grow'
                getOptionLabel={getOptionLabel}
                isOptionSelected={isOptionSelected}
                isOptionDisabled={isOptionDisabled}
                placeholder={props.placeholder}
                // cacheOptions
                isDisabled={props.disabled}
                defaultOptions
                value={props.value}
                loadOptions={debouncedResolver}
                onChange={(entity: any) => props.onChange(entity)}
                styles={styles as any}
            />
        </div>
    )
}
