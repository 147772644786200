import {
    mdiClose,
    mdiFileExcel,
    mdiFolderNetwork,
    mdiLoading,
    mdiTable,
    mdiTableMultiple,
} from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
    useExportPOILProjectBySubjectXlsxMutation,
    useExportPOILProjectXlsxMutation,
    useExportPOILSummaryHtmlMutation,
    useExportPOILSummaryPdfMutation,
    useExportPOILSummarySideLetterMutation,
    useExportPOILSummaryXlsxMutation,
    useGetPOILSummaryQuery,
} from '../../api/for-company/oil.api'
import { selectProjectById } from '../../api/for-company/projects.api'
import { ExportButton } from '../../atoms/Export/ExportButton'
import { ExportDocButton } from '../../atoms/Export/ExportDocButton'
import { ExportHtmlButton } from '../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../atoms/Export/ExportXlsButton'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Separator } from '../../atoms/Separator'
import { Tooltip } from '../../atoms/Tooltip'
import { OILStatus } from '../../domain/oil.domain'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { openFile } from '../../lib/open-file'
import { routes } from '../../routes'
import { useAppSelector } from '../../store'
import { ForProjectRole } from '../Auth/ForProjectRole'

const statusList = [
    OILStatus.UnderReview,
    OILStatus.Open,
    OILStatus.Pending,
    OILStatus.Critical,
    OILStatus.Closed,
    OILStatus.SideLetter,
]
const statusMap: Record<OILStatus, { name: string; bg: string; border: string }> = {
    [OILStatus.UnderReview]: {
        name: 'Under Review',
        bg: 'bg-teal-200',
        border: 'border-teal-700',
    },
    [OILStatus.Open]: { name: 'Open', bg: 'bg-yellow-200', border: 'border-yellow-700' },
    [OILStatus.Pending]: { name: 'Pending', bg: 'bg-indigo-200', border: 'border-indigo-500' },
    [OILStatus.Critical]: { name: 'Critical', bg: 'bg-red-200', border: 'border-red-500' },
    [OILStatus.Closed]: { name: 'Closed', bg: 'bg-green-200', border: 'border-green-700' },
    [OILStatus.SideLetter]: {
        name: 'Side Letter',
        bg: 'bg-indigo-300',
        border: 'border-indigo-700',
    },
}

const computeTotal = (statuses: Record<OILStatus, number>[]) =>
    statuses.reduce(
        (acc, statuses) => ({
            [OILStatus.UnderReview]:
                acc[OILStatus.UnderReview] + statuses[OILStatus.UnderReview] ?? 0,
            [OILStatus.Open]: acc[OILStatus.Open] + statuses[OILStatus.Open] ?? 0,
            [OILStatus.Pending]: acc[OILStatus.Pending] + statuses[OILStatus.Pending] ?? 0,
            [OILStatus.Critical]: acc[OILStatus.Critical] + statuses[OILStatus.Critical] ?? 0,
            [OILStatus.Closed]: acc[OILStatus.Closed] + statuses[OILStatus.Closed] ?? 0,
            [OILStatus.SideLetter]: acc[OILStatus.SideLetter] + statuses[OILStatus.SideLetter] ?? 0,
        }),
        {
            [OILStatus.UnderReview]: 0,
            [OILStatus.Open]: 0,
            [OILStatus.Pending]: 0,
            [OILStatus.Critical]: 0,
            [OILStatus.Closed]: 0,
            [OILStatus.SideLetter]: 0,
        },
    )

const StatusCount: React.FC<{ status: OILStatus; count: number; className?: string }> = props => (
    <div
        className={classNames('p-4 text-center', props.className, {
            [statusMap[props.status].bg]: props.count > 0,
            'text-atamblue-300 ': !(props.count > 0),
            'text-atamblue-900': props.count > 0,
        })}
    >
        {props.count}
    </div>
)

export const POILSummary: React.FC<{ projectId: number }> = props => {
    const { t } = useTranslation('summary')

    const { data } = useGetPOILSummaryQuery({ id: props.projectId })
    const [exportSideLetter, exportSideLetterMeta] = useExportPOILSummarySideLetterMutation()
    const [exportHtml, exportHtmlMeta] = useExportPOILSummaryHtmlMutation()
    const [exportPdf, exportPdfMeta] = useExportPOILSummaryPdfMutation()
    const [exportXlsx, exportXlsxMeta] = useExportPOILSummaryXlsxMutation()
    const [exportProjectXlsx, exportProjectXlsxMeta] = useExportPOILProjectXlsxMutation()
    const [
        exportProjectBySubjectXlsx,
        exportProjectBySubjectXlsxMeta,
    ] = useExportPOILProjectBySubjectXlsxMutation()

    const project = useAppSelector(selectProjectById(props.projectId))
    if (!project || !data) {
        return null
    }

    const summary = data.results

    const totalAuditor = computeTotal(summary.map(subject => subject.auditorStatus))

    const totalAuditee = computeTotal(summary.map(subject => subject.auditeeStatus))
    const cumulativeAuditorOpen =
        totalAuditor[OILStatus.Open] +
        totalAuditor[OILStatus.Pending] +
        totalAuditor[OILStatus.Critical]
    const cumulativeAuditorClosed =
        totalAuditor[OILStatus.Closed] + totalAuditor[OILStatus.SideLetter]
    const cumulativeAuditeeOpen =
        totalAuditee[OILStatus.Open] +
        totalAuditee[OILStatus.Pending] +
        totalAuditee[OILStatus.Critical]
    const cumulativeAuditeeClosed =
        totalAuditee[OILStatus.Closed] + totalAuditee[OILStatus.SideLetter]

    return (
        <div className='mb-10'>
            <div className='flex justify-end mb-1'>
                <div className='flex ml-12'>
                    <ForProjectRole
                        permission={ProjectRolePermissions.ProjectSummaryExportSummaryDoc}
                        projectId={props.projectId}
                    >
                        <ExportDocButton
                            isLoading={exportSideLetterMeta.isLoading}
                            onClick={() =>
                                exportSideLetter({
                                    id: props.projectId,
                                })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.ProjectSummaryExportSummaryXls}
                        projectId={props.projectId}
                    >
                        <Modal
                            title='Select export style'
                            body={({ close }) => (
                                <div className='grid gap-8 grid-columns-2 place-content-center mt-8'>
                                    <IconButton
                                        path={
                                            exportProjectBySubjectXlsxMeta.isLoading
                                                ? mdiLoading
                                                : mdiTableMultiple
                                        }
                                        spin={exportProjectBySubjectXlsxMeta.isLoading}
                                        disabled={exportProjectBySubjectXlsxMeta.isLoading}
                                        size={1}
                                        className='h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                                        iconClassName='m-2 text-green-300 group-hover:text-green-500'
                                        onClick={() =>
                                            exportProjectBySubjectXlsx({
                                                id: props.projectId,
                                            })
                                                .unwrap()
                                                .then(({ path }) => openFile(path))
                                                .then(() => close())
                                        }
                                    >
                                        Each Subject on its own sheet
                                    </IconButton>
                                    <IconButton
                                        path={
                                            exportProjectXlsxMeta.isLoading ? mdiLoading : mdiTable
                                        }
                                        spin={exportProjectXlsxMeta.isLoading}
                                        disabled={exportProjectXlsxMeta.isLoading}
                                        size={1}
                                        className='h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                                        iconClassName='m-2 text-green-300 group-hover:text-green-500'
                                        onClick={() =>
                                            exportProjectXlsx({
                                                id: props.projectId,
                                            })
                                                .unwrap()
                                                .then(({ path }) => openFile(path))
                                                .then(() => close())
                                        }
                                    >
                                        All Subjects on a single sheet
                                    </IconButton>
                                    <div className='col-span-2 mt-1 text-center'>
                                        <IconButton
                                            path={mdiClose}
                                            size={1}
                                            className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                                            iconClassName='m-2 text-red-400 group-hover:text-red-500'
                                            onClick={() => close()}
                                        >
                                            Close
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        >
                            <Tooltip text='Export all POIL as Excel' className='w-full h-full'>
                                <ExportButton
                                    icon={mdiFileExcel}
                                    pill='OIL'
                                    pillClassName='bg-white border-green-900 text-green-800'
                                    className='mr-2 bg-white text-green-900 hover:bg-green-900 hover:text-white'
                                />
                            </Tooltip>
                        </Modal>
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.ProjectSummaryExportSummaryXls}
                        projectId={props.projectId}
                    >
                        <ExportXlsButton
                            isLoading={exportXlsxMeta.isLoading}
                            onClick={() =>
                                exportXlsx({
                                    id: props.projectId,
                                })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.ProjectSummaryExportSummaryPdf}
                        projectId={props.projectId}
                    >
                        <ExportPdfButton
                            isLoading={exportPdfMeta.isLoading}
                            onClick={() =>
                                exportPdf({
                                    id: props.projectId,
                                })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.ProjectSummaryExportSummaryStatic}
                        projectId={props.projectId}
                    >
                        <ExportHtmlButton
                            isLoading={exportHtmlMeta.isLoading}
                            onClick={() =>
                                exportHtml({
                                    id: props.projectId,
                                })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                            }
                        />
                    </ForProjectRole>
                </div>
            </div>
            <div className='flex'>
                <div className='w-2/8 flex flex-col items-center shadow-sm bg-white mr-0.5 mb-0.5 px-4 pt-4 rounded-tl-2xl rounded-bl-2xl'>
                    <div className='text-atamblue-700 font-semibold'>{t('ReviewSubject')}</div>
                    <Separator className='w-full h-0.5 my-4 bg-atamblue-300' />
                    <Icon path={mdiFolderNetwork} size={1} className='text-atamsky-900'></Icon>
                </div>
                <div className='w-3/8 flex flex-col items-center shadow-sm bg-white mx-0.5 mb-0.5 pt-4 px-4'>
                    <Tooltip
                        text={project?.auditorName}
                        className='w-1/2 justify-center text-teal-700 font-semibold'
                    >
                        {project?.auditorIcao}
                    </Tooltip>
                    <Separator className='w-full h-0.5 my-4 bg-teal-300' />
                    <div className='w-full  grid grid-cols-6'>
                        {statusList.map(status => (
                            <div
                                key={status}
                                className={classNames(
                                    'flex items-center justify-center mx-1 first:ml-0 last:mr-0 border-b-4 text-center text-sm leading-4 text-atamblue-700',
                                    statusMap[status].border,
                                )}
                            >
                                {statusMap[status].name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='w-3/8 flex flex-col items-center shadow-sm bg-white ml-0.5 mb-0.5 pt-4 px-4 rounded-tr-2xl rounded-br-2xl'>
                    <Tooltip
                        text={project?.auditeeName}
                        className='w-1/2 justify-center text-green-500 font-semibold'
                    >
                        {project?.auditeeIcao}
                    </Tooltip>
                    <Separator className='w-full h-0.5 my-4 bg-green-250' />
                    <div className='w-full  grid grid-cols-6'>
                        {statusList.map(status => (
                            <div
                                key={status}
                                className={classNames(
                                    'flex items-center justify-center mx-1 first:ml-0 last:mr-0 border-b-4 text-center text-sm leading-4 text-atamblue-700',
                                    statusMap[status].border,
                                )}
                            >
                                {statusMap[status].name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {summary.map(subject => (
                <div key={subject.name} className='flex group'>
                    <Link
                        to={routes.POIL.build({
                            projectId: props.projectId,
                            subjectId: subject.id,
                        })}
                        className='w-2/8 bg-white shadow-sm my-0.5 mr-0.5 rounded-tl-2xl rounded-bl-2xl p-4 text-sm text-atamblue-700 hover:text-atamsky-900 group-hover:shadow-lg group-hover:border-l group-hover:border-t group-hover:border-b group-hover:border-atamsky-300 cursor-pointer'
                    >
                        {subject.name}
                    </Link>

                    <div className='w-3/8 grid grid-cols-6 shadow-sm m-0.5 px-4 bg-white text-sm text-atamblue-900 group-hover:shadow-lg group-hover:border-t group-hover:border-b  group-hover:border-atamsky-300'>
                        {statusList.map(status => (
                            <StatusCount
                                className='mx-1 first:ml-0 last:mr-0'
                                key={status}
                                status={status}
                                count={subject.auditorStatus[status]}
                            />
                        ))}
                    </div>
                    <div className='w-3/8 grid grid-cols-6 shadow-sm my-0.5 ml-0.5 px-4 bg-white text-sm text-atamblue-900 rounded-tr-2xl rounded-br-2xl group-hover:shadow-lg group-hover:border-t group-hover:border-b group-hover:border-r group-hover:border-atamsky-300'>
                        {statusList.map(status => (
                            <StatusCount
                                className='mx-1 first:ml-0 last:mr-0'
                                key={status}
                                status={status}
                                count={subject.auditeeStatus[status]}
                            />
                        ))}
                    </div>
                </div>
            ))}
            <div className='flex group'>
                <div className='w-2/8 grid items-center bg-white shadow-sm my-0.5 mr-0.5 rounded-tl-2xl rounded-bl-2xl p-4 group-hover:shadow-lg group-hover:border-l group-hover:border-t group-hover:border-b group-hover:border-atamsky-300'>
                    <div className='text-atamblue-700 font-semibold'>{t('Total')}</div>
                    <Separator className='w-full h-0.5 my-4 bg-atamblue-300' />
                    <div className='text-atamblue-700 font-semibold'>{t('Cumulative')}</div>
                </div>
                <div className='w-3/8 grid grid-cols-6 shadow-sm m-0.5 px-4 bg-white text-sm text-atamblue-900  group-hover:shadow-lg group-hover:border-t group-hover:border-b group-hover:border-atamsky-300'>
                    {statusList.map(status => (
                        <StatusCount
                            className={classNames(
                                'flex items-center justify-center mx-1 first:ml-0 last:mr-0 border-t-4',
                                statusMap[status].border,
                                { 'row-span-2 border-b-4': status === OILStatus.UnderReview },
                            )}
                            key={status}
                            status={status}
                            count={totalAuditor[status]}
                        />
                    ))}
                    <StatusCount
                        className={classNames(
                            'mx-1 border-b-4 col-span-3',
                            statusMap[OILStatus.Open].border,
                        )}
                        key={'cumulativeAuditorOpen'}
                        status={OILStatus.Open}
                        count={cumulativeAuditorOpen}
                    />
                    <StatusCount
                        className={classNames(
                            'mx-1 border-b-4 col-span-2',
                            statusMap[OILStatus.Closed].border,
                        )}
                        key={'cumulativeAuditorClosed'}
                        status={OILStatus.Closed}
                        count={cumulativeAuditorClosed}
                    />
                </div>
                <div className='w-3/8 grid grid-cols-6 shadow-sm my-0.5 ml-0.5 px-4 bg-white text-sm text-atamblue-900 rounded-tr-2xl rounded-br-2xl group-hover:shadow-lg group-hover:border-t group-hover:border-b group-hover:border-r group-hover:border-atamsky-300'>
                    {statusList.map(status => (
                        <StatusCount
                            className={classNames(
                                'flex items-center justify-center mx-1 first:ml-0 last:mr-0 border-t-4',
                                statusMap[status].border,
                                { 'row-span-2 border-b-4': status === OILStatus.UnderReview },
                            )}
                            key={status}
                            status={status}
                            count={totalAuditee[status]}
                        />
                    ))}
                    <StatusCount
                        className={classNames(
                            'mx-1 border-b-4 col-span-3',
                            statusMap[OILStatus.Open].border,
                        )}
                        key={'cumulativeAuditeeOpen'}
                        status={OILStatus.Open}
                        count={cumulativeAuditeeOpen}
                    />
                    <StatusCount
                        className={classNames(
                            'mx-1 border-b-4 col-span-2',
                            statusMap[OILStatus.Closed].border,
                        )}
                        key={'cumulativeAuditeeClosed'}
                        status={OILStatus.Closed}
                        count={cumulativeAuditeeClosed}
                    />
                </div>
            </div>
        </div>
    )
}
