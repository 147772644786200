import {
    mdiAlertDecagram,
    mdiCheckAll,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCollapseAll,
    mdiDeleteEmpty,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiExpandAll,
    mdiHistory,
    mdiInformationOutline,
    mdiTrashCan,
} from '@mdi/js'
import Icon from '@mdi/react'
import { RequestStatusFlags } from '@reduxjs/toolkit/dist/query/core/apiState'
import classNames from 'classnames'
import { format } from 'date-fns'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ListResponse } from '../../api/common'
import {
    useEditOILSubjectMutation,
    useEditObservationDescriptionMutation,
    useEditAuditorStatusMutation,
    useEditAuditeeStatusMutation,
    useAddCommentMutation,
    useRemoveCommentMutation,
    useTrashMutation,
    useUntrashMutation,
    useMarkAsCriticalMutation,
    useUnmarkAsCriticalMutation,
} from '../../api/for-company/oil.api'
import { selectProjectById } from '../../api/for-company/projects.api'
import { ExportHtmlButton } from '../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../atoms/Export/ExportXlsButton'
import { FloatingButton } from '../../atoms/FloatingButton'
import { IconButton } from '../../atoms/IconButton'
import { MenuItem } from '../../atoms/Menu'
import { Modal } from '../../atoms/Modal/Modal'
import { DefaultCellValue } from '../../atoms/Table/DefaultCell'
import { DefaultHeader } from '../../atoms/Table/DefaultHeader'
import { BooleanActiveFilter } from '../../atoms/Table/Filters/BooleanFilter'
import { DateActiveFilter, DateFilter } from '../../atoms/Table/Filters/DateFilter'
import { TextActiveFilter, TextFilter } from '../../atoms/Table/Filters/TextFilter'
import { HeaderMenu } from '../../atoms/Table/HeaderMenu'
import { SortingToggle } from '../../atoms/Table/SortingToggle'
import { Table, TableColumn } from '../../atoms/Table/Table'
import { Tooltip } from '../../atoms/Tooltip'
import { OIL } from '../../domain/oil.domain'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { openFile } from '../../lib/open-file'
import { useAppSelector } from '../../store'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { AddNewOIL } from './AddNewOIL'
import { CommentsCell } from './CommentsColumn'
import { OILModal } from './common/OILModal'
import { StatusActiveFilter, StatusFilter, StatusFilterValue } from './common/StatusFilter'
import { ObservationDescriptionCell } from './ObservationDescriptionColumn'
import { StatusCell } from './StatusColumn'
import { SubjectColumnCell } from './SubjectColumn'
import { ViewHistory } from './ViewHistory'

type DateEnteredValue = { from: string; to: string }
type OILPSF = {
    dateEntered: DateEnteredValue
    subject: string
    observationDescription: string
    statuses: StatusFilterValue
    comments: string
    showCriticalPathOnly: boolean
    includeTrashedItems: boolean
    showTrashedItemsOnly: boolean
}

const OILColumns = ({
    projectId,
    subjectId,
}: {
    projectId: number
    subjectId: number
}): TableColumn<OIL, OILPSF>[] => {
    return [
        {
            id: 'menu',
            Header: ({
                allCellsExpanded,
                allCellsSelected,
                toggleExpandAllCells,
                toggleSelectAllCells,
                filters,
                setFilter,
            }) => (
                <HeaderMenu
                    className='w-72'
                    body={({ close: closeMenu }) => {
                        const withCloseMenu = (fn: () => void) => () => {
                            fn()
                            closeMenu()
                        }
                        return (
                            <>
                                <MenuItem
                                    icon={allCellsExpanded ? mdiCollapseAll : mdiExpandAll}
                                    onClick={withCloseMenu(toggleExpandAllCells)}
                                >
                                    {allCellsExpanded ? 'Collapse All Items' : 'Expand All Items'}
                                </MenuItem>
                                <MenuItem
                                    icon={mdiCheckAll}
                                    onClick={withCloseMenu(toggleSelectAllCells)}
                                >
                                    {allCellsSelected ? 'Deselect All Items' : 'Select All Items'}
                                </MenuItem>
                                <MenuItem
                                    icon={mdiAlertDecagram}
                                    onClick={withCloseMenu(() =>
                                        setFilter({
                                            showCriticalPathOnly: !filters.showCriticalPathOnly,
                                        }),
                                    )}
                                >
                                    <div className='flex justify-between items-center w-full'>
                                        <div>Show Critical Path Items Only</div>
                                        <Icon
                                            path={
                                                filters.showCriticalPathOnly
                                                    ? mdiCheckboxMarked
                                                    : mdiCheckboxBlankOutline
                                            }
                                            size={0.75}
                                        />
                                    </div>
                                </MenuItem>
                                <MenuItem
                                    icon={mdiDeleteEmpty}
                                    onClick={withCloseMenu(() =>
                                        setFilter({
                                            includeTrashedItems: !filters.includeTrashedItems,
                                            showTrashedItemsOnly: false,
                                        }),
                                    )}
                                >
                                    <div className='flex justify-between items-center w-full'>
                                        <div>Include Trashed Items</div>
                                        <Icon
                                            path={
                                                filters.includeTrashedItems
                                                    ? mdiCheckboxMarked
                                                    : mdiCheckboxBlankOutline
                                            }
                                            size={0.75}
                                        />
                                    </div>
                                </MenuItem>
                                <MenuItem
                                    icon={mdiTrashCan}
                                    onClick={withCloseMenu(() =>
                                        setFilter({
                                            showTrashedItemsOnly: !filters.showTrashedItemsOnly,
                                            includeTrashedItems: false,
                                        }),
                                    )}
                                >
                                    <div className='flex justify-between items-center w-full'>
                                        <div>Show Trashed Items Only</div>
                                        <Icon
                                            path={
                                                filters.showTrashedItemsOnly
                                                    ? mdiCheckboxMarked
                                                    : mdiCheckboxBlankOutline
                                            }
                                            size={0.75}
                                        />
                                    </div>
                                </MenuItem>
                            </>
                        )
                    }}
                />
            ),
            Cell: ({ value, isExpanded, isSelected, toggleExpanded, toggleSelected }) => {
                const [markCritical] = useMarkAsCriticalMutation()
                const [unmarkCritical] = useUnmarkAsCriticalMutation()
                const [trash] = useTrashMutation()
                const [untrash] = useUntrashMutation()

                const project = useAppSelector(selectProjectById(projectId))
                if (!project) {
                    return null
                }
                const isReadOnly = project.isArchived || value.isTrashed

                return !isExpanded ? (
                    <DefaultCellValue>
                        <IconButton
                            className='text-atamblue-300 hover:text-atamsky-900'
                            path={mdiDotsHorizontal}
                            size={0.75}
                            tooltip='Expand item'
                            onClick={() => toggleExpanded(true)}
                        />
                    </DefaultCellValue>
                ) : (
                    <div className='flex flex-col justify-center' style={{ minHeight: '170px' }}>
                        <IconButton
                            className='my-2 text-atamblue-300 hover:text-atamsky-900'
                            path={mdiDotsVertical}
                            size={0.75}
                            tooltip='Collapse item'
                            onClick={() => toggleExpanded(false)}
                        />

                        <IconButton
                            className='my-2 text-atamblue-300 hover:text-atamsky-900'
                            path={isSelected ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                            size={0.75}
                            tooltip='Select item'
                            disabled={isReadOnly}
                            onClick={() => toggleSelected()}
                        />

                        <ForProjectRole
                            permission={ProjectRolePermissions.OILMarkAsCritical}
                            projectId={projectId}
                        >
                            <IconButton
                                className={classNames(
                                    'my-2 text-atamblue-300 hover:text-atamsky-900',
                                    {
                                        'text-red-500': value.isCritical,
                                    },
                                )}
                                path={mdiAlertDecagram}
                                size={0.75}
                                tooltip={
                                    value.isCritical
                                        ? 'Unmark as Critical Path Item'
                                        : 'Mark as Critical Path Item'
                                }
                                disabled={isReadOnly}
                                onClick={() => {
                                    const args = {
                                        projectId,
                                        subjectId,
                                        id: value.id,
                                    }
                                    if (value.isCritical) {
                                        return unmarkCritical(args)
                                    } else {
                                        return markCritical(args)
                                    }
                                }}
                            />
                        </ForProjectRole>

                        {!value.isTrashed ? (
                            <ForProjectRole
                                permission={ProjectRolePermissions.OILTrash}
                                projectId={projectId}
                            >
                                <IconButton
                                    className='my-2 text-atamblue-300 hover:text-atamsky-900'
                                    path={mdiDeleteEmpty}
                                    size={0.75}
                                    tooltip='Trash Item'
                                    onClick={() =>
                                        trash({
                                            projectId,
                                            subjectId,
                                            id: value.id,
                                        })
                                    }
                                />
                            </ForProjectRole>
                        ) : (
                            <ForProjectRole
                                permission={ProjectRolePermissions.OILUntrash}
                                projectId={projectId}
                            >
                                <IconButton
                                    className='my-2 text-atamblue-300 hover:text-atamsky-900'
                                    path={mdiDeleteEmpty}
                                    size={0.75}
                                    tooltip='Restore Item'
                                    onClick={() =>
                                        untrash({
                                            projectId,
                                            subjectId,
                                            id: value.id,
                                        })
                                    }
                                />
                            </ForProjectRole>
                        )}

                        <ForProjectRole
                            permission={ProjectRolePermissions.OILViewHistory}
                            projectId={projectId}
                        >
                            <OILModal
                                className='h-44'
                                body={({ close }) => (
                                    <ViewHistory
                                        projectId={projectId}
                                        subjectId={subjectId}
                                        oilId={value.id}
                                        onClose={close}
                                    />
                                )}
                                render={({ open }) => (
                                    <IconButton
                                        className='my-2 text-atamblue-300 hover:text-atamsky-900'
                                        path={mdiHistory}
                                        size={0.75}
                                        tooltip='Show history'
                                        disabled={isReadOnly}
                                        onClick={open}
                                    />
                                )}
                            />
                        </ForProjectRole>
                    </div>
                )
            },
            width: 3,
        },
        {
            id: 'itemNumber',
            Header: () => <DefaultHeader>Item NBR</DefaultHeader>,
            Cell: ({ value }) => <DefaultCellValue>{value.itemNumber}</DefaultCellValue>,
            Sort: ({ direction, toggleDirection }) => (
                <SortingToggle direction={direction} onToggle={toggleDirection} />
            ),
            width: 6,
        },
        {
            id: 'dateEntered',
            Header: () => <DefaultHeader>Date Entered</DefaultHeader>,
            Cell: ({ value }) => (
                <DefaultCellValue>
                    {format(new Date(value.dateEntered), 'dd-MMM-yyyy')}
                </DefaultCellValue>
            ),
            Sort: ({ direction, toggleDirection }) => (
                <SortingToggle direction={direction} onToggle={toggleDirection} />
            ),
            Filter: ({ filters, setFilter }) => {
                const value = filters.dateEntered
                    ? {
                          from: new Date(filters.dateEntered.from),
                          to: new Date(filters.dateEntered.to),
                      }
                    : undefined
                return (
                    <DateFilter
                        value={value}
                        onChange={dateEntered =>
                            setFilter({
                                dateEntered: {
                                    from: dateEntered.from.toISOString(),
                                    to: dateEntered.to.toISOString(),
                                },
                            })
                        }
                    />
                )
            },
            ActiveFilter: ({ value, clearFilter }) => {
                const v = value as DateEnteredValue
                return (
                    <DateActiveFilter
                        label='Date Entered'
                        value={{ from: new Date(v.from), to: new Date(v.to) }}
                        onClear={clearFilter}
                    />
                )
            },
            width: 12,
        },
        {
            id: 'subject',
            Header: () => <DefaultHeader>Subject</DefaultHeader>,
            Cell: ({ value, isExpanded }) => {
                const [editSubject] = useEditOILSubjectMutation()
                const project = useAppSelector(selectProjectById(projectId))
                if (!project) {
                    return null
                }

                return (
                    <SubjectColumnCell
                        value={value.subject}
                        projectId={projectId}
                        itemNumber={value.itemNumber}
                        isReadOnly={project.isArchived || value.isTrashed}
                        isExpanded={isExpanded}
                        onChange={subject =>
                            editSubject({
                                projectId,
                                subjectId,
                                id: value.id,
                                subject,
                            }).unwrap()
                        }
                    />
                )
            },
            Sort: ({ direction, toggleDirection }) => (
                <SortingToggle direction={direction} onToggle={toggleDirection} />
            ),
            Filter: ({ filters, setFilter }) => (
                <TextFilter value={filters.subject} onChange={subject => setFilter({ subject })} />
            ),
            ActiveFilter: ({ value, clearFilter }) => (
                <TextActiveFilter label='Subject' value={value as string} onClear={clearFilter} />
            ),
            width: 13,
        },
        {
            id: 'observationDescription',
            Header: () => <DefaultHeader>Observation Description</DefaultHeader>,
            Cell: ({ value, isExpanded }) => {
                const [editObservationDescription] = useEditObservationDescriptionMutation()
                const project = useAppSelector(selectProjectById(projectId))
                if (!project) {
                    return null
                }
                return (
                    <ObservationDescriptionCell
                        value={value.observationDescription}
                        projectId={projectId}
                        itemNumber={value.itemNumber}
                        isReadOnly={project.isArchived || value.isTrashed}
                        isExpanded={isExpanded}
                        onChange={observationDescription =>
                            editObservationDescription({
                                projectId,
                                subjectId,
                                id: value.id,
                                observationDescription,
                            }).unwrap()
                        }
                    />
                )
            },
            Filter: ({ filters, setFilter }) => (
                <TextFilter
                    value={filters.observationDescription}
                    onChange={observationDescription => setFilter({ observationDescription })}
                />
            ),
            ActiveFilter: ({ value, clearFilter }) => (
                <TextActiveFilter
                    label='Observation Description'
                    value={value as string}
                    onClear={clearFilter}
                />
            ),
            width: 28,
        },
        {
            id: 'statuses',
            Header: () => {
                const project = useAppSelector(selectProjectById(projectId))
                return (
                    <div>
                        <div>Status</div>
                        <div className='flex justify-around'>
                            <Tooltip text={project?.auditorName} className='w-1/2 justify-center'>
                                {project?.auditorIcao}
                            </Tooltip>
                            <Tooltip text={project?.auditeeName} className='w-1/2 justify-center'>
                                {project?.auditeeIcao}
                            </Tooltip>
                        </div>
                    </div>
                )
            },
            Cell: ({ value, isExpanded }) => {
                const [editAuditorStatus] = useEditAuditorStatusMutation()
                const [editAuditeeStatus] = useEditAuditeeStatusMutation()
                const project = useAppSelector(selectProjectById(projectId))
                if (!project) {
                    return null
                }
                return (
                    <StatusCell
                        auditorStatus={value.auditorStatus}
                        auditeeStatus={value.auditeeStatus}
                        itemNumber={value.itemNumber}
                        isExpanded={isExpanded}
                        isReadOnly={project.isArchived || value.isTrashed}
                        onAuditorChange={auditorStatus =>
                            editAuditorStatus({
                                projectId,
                                subjectId,
                                id: value.id,
                                auditorStatus,
                            }).unwrap()
                        }
                        onAuditeeChange={auditeeStatus =>
                            editAuditeeStatus({
                                projectId,
                                subjectId,
                                id: value.id,
                                auditeeStatus,
                            }).unwrap()
                        }
                    />
                )
            },
            Filter: ({ filters, setFilter }) => (
                <StatusFilter
                    value={filters.statuses}
                    onChange={statuses => setFilter({ statuses })}
                />
            ),
            ActiveFilter: ({ value, clearFilter }) => (
                <StatusActiveFilter value={value as StatusFilterValue} onClear={clearFilter} />
            ),
            width: 10,
        },
        {
            id: 'comments',
            Header: () => {
                const project = useAppSelector(selectProjectById(projectId))
                return (
                    <div>
                        <div>Comments</div>
                        <div className='flex justify-around'>
                            <Tooltip text={project?.auditorName} className='w-1/2 justify-center'>
                                {project?.auditorIcao}
                            </Tooltip>
                            <Tooltip text={project?.auditeeName} className='w-1/2 justify-center'>
                                {project?.auditeeIcao}
                            </Tooltip>
                        </div>
                    </div>
                )
            },
            Cell: ({ value, isExpanded }) => {
                const [addComment] = useAddCommentMutation()
                const [removeComment] = useRemoveCommentMutation()
                const project = useAppSelector(selectProjectById(projectId))
                if (!project) {
                    return null
                }
                return (
                    <CommentsCell
                        projectId={projectId}
                        value={value.comments}
                        itemNumber={value.itemNumber}
                        isExpanded={isExpanded}
                        isReadOnly={project.isArchived || value.isTrashed}
                        onAddComment={content =>
                            addComment({
                                projectId,
                                subjectId,
                                id: value.id,
                                content,
                            })
                        }
                        onRemoveComment={commentId =>
                            removeComment({
                                projectId,
                                subjectId,
                                id: value.id,
                                commentId,
                            })
                        }
                    />
                )
            },
            Filter: ({ filters, setFilter }) => (
                <TextFilter
                    value={filters.comments}
                    onChange={comments => setFilter({ comments })}
                />
            ),
            ActiveFilter: ({ value, clearFilter }) => (
                <TextActiveFilter label='Comments' value={value as string} onClear={clearFilter} />
            ),
            width: 28,
        },
    ]
}

export const ListOIL: React.FC<{
    projectId: number
    subjectId: number
    data: ListResponse<OIL>
    exportHtml: (selectedOil: number[]) => Promise<{ path: string }>
    exportHtmlMeta: RequestStatusFlags
    exportPdf: (selectedOil: number[]) => Promise<{ path: string }>
    exportPdfMeta: RequestStatusFlags
    exportXlsx: (selectedOil: number[]) => Promise<{ path: string }>
    exportXlsxMeta: RequestStatusFlags
    onChange: (args: Record<string, unknown>) => void
}> = props => {
    const { t } = useTranslation('subjects')
    const oilColumns = useMemo(
        () => OILColumns({ projectId: props.projectId, subjectId: props.subjectId }),
        [props.projectId, props.subjectId],
    )

    const project = useAppSelector(selectProjectById(props.projectId))

    if (!props.data || !project) {
        return null
    }

    return (
        <>
            <Table
                columns={oilColumns}
                data={props.data}
                className='mb-12'
                headerClassName='py-3'
                rowClassName={({ isCritical, isTrashed }, { isSelected }) =>
                    classNames('border border-transparent', {
                        'border-red-500': isCritical,
                        'opacity-75 bg-ebony-400 border-ebony-500': isTrashed,
                        'border-atamsky-700 shadow-xl': isSelected,
                    })
                }
                additionalFilters={{
                    showCriticalPathOnly: ({ value, clearFilter }) => (
                        <BooleanActiveFilter
                            key='showCriticalPathOnly'
                            label='Showing Critical Path Only'
                            value={value}
                            onClear={clearFilter}
                        />
                    ),
                    includeTrashedItems: ({ value, clearFilter }) => (
                        <BooleanActiveFilter
                            key='includeTrashedItems'
                            label='Including Trashed Items'
                            value={value}
                            onClear={clearFilter}
                        />
                    ),
                    showTrashedItemsOnly: ({ value, clearFilter }) => (
                        <BooleanActiveFilter
                            key='showTrashedItemsOnly'
                            label='Showing Trashed Items Only'
                            value={value}
                            onClear={clearFilter}
                        />
                    ),
                }}
                tableActions={({ selectedCells }) => (
                    <div className='flex items-center ml-12 mb-2'>
                        <div className='flex mr-0.5'>
                            <div className='text-sm text-atamblue-700'>
                                {selectedCells.length > 0 ? (
                                    <Tooltip
                                        text={
                                            'Only selected items will be exported. To export all items of this subject clear any selected item.'
                                        }
                                    >
                                        <div className='flex justify-between items-center rounded-xl border border-atamsky-300 p-2 shadow-md'>
                                            <Icon
                                                path={mdiInformationOutline}
                                                size={0.75}
                                                className='mr-0.5 text-atamblue-300'
                                            />
                                            <div className='py-0.2'>
                                                <span className='text-atamblue-300'>
                                                    Selected Items:
                                                </span>{' '}
                                                {selectedCells.length}
                                            </div>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportXls}
                            projectId={project.id}
                        >
                            <ExportXlsButton
                                isLoading={props.exportXlsxMeta.isLoading}
                                onClick={() =>
                                    props
                                        .exportXlsx(selectedCells)
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportPdf}
                            projectId={project.id}
                        >
                            <ExportPdfButton
                                isLoading={props.exportPdfMeta.isLoading}
                                onClick={() =>
                                    props
                                        .exportPdf(selectedCells)
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportStatic}
                            projectId={project.id}
                        >
                            <ExportHtmlButton
                                isLoading={props.exportHtmlMeta.isLoading}
                                onClick={() =>
                                    props
                                        .exportHtml(selectedCells)
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                    </div>
                )}
                onChange={props.onChange}
            />
            {!project.isArchived ? (
                <ForProjectRole
                    permission={ProjectRolePermissions.OILCreate}
                    projectId={project.id}
                >
                    <Modal
                        fullwidth
                        body={({ close }) => (
                            <AddNewOIL
                                projectId={props.projectId}
                                subjectId={props.subjectId}
                                onSave={close}
                                onCancel={close}
                            />
                        )}
                    >
                        <FloatingButton>
                            <span className='flex-none pr-2 text-2xl'>+</span>
                            <span className='flex-none'>{t('AddNewItem')}</span>
                        </FloatingButton>
                    </Modal>
                </ForProjectRole>
            ) : null}
        </>
    )
}
